import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Link from 'src/components/link/index'
import ReadyToGo from 'src/components/readytogo/index'

import styles from './index.module.scss'


const NotFoundPage = () => {

    return (
        <div>
            <SEO title="404 - Not found" />
            <Header hasBack={true} />

            <div className={styles.notFound}>
                <Title h1="404 - Not found" />
                <Inner>
                    <div className={styles.text}>
                        <p>お探しのページは一時的にアクセスできない状態か、移動もしくは削除された可能性があります。</p>
                    </div>
                    <div className={styles.gotoTop}>
                        <Link to="/">トップページに戻る</Link>
                    </div>
                </Inner>
            </div>

            <ReadyToGo />
        </div>
    )
}

export default NotFoundPage
